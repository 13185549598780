<template>
    <div>
        <app-header :title="title" :isShowBack="true"></app-header>
        <div class="baseInfo">
            <div class="baseInfo-title">
                选经销商
            </div>
            <div class="form-box">
                <el-form ref="myForm" :model="myForm" :rules="rules">
                    <el-form-item label-width="120px" style="display: inline-block;" label="经销商" prop="merchantId">
                        <el-select v-model="myForm.merchantId" placeholder="请选择供应商" filterable @change="hasValue()">
                            <el-option v-for="item in dealerList" :key="item.id" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>

        </div>
        <div class="goods">
            <div class="goods-title">选择经销商品</div>
            <div class="goods-search">
                <div>商品查询<span style="color:red;">*</span></div>
                <el-autocomplete v-model.trim="itemName" :fetch-suggestions="searchItem" placeholder="请输入商品名称/商品编号"
                    @select="handleSelect" clearable :disabled="notEdit"></el-autocomplete>
            </div>
            <div class="table-box">
                <div style="padding-bottom:8px;width:100px;">商品明细<span style="color:red;">*</span></div>
                <div class="goods-table">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
                        :header-cell-class-name="must" style="width: 100%;height:380px;overflow:auto;"
                        @selection-change="handSelectionChange">
                        <el-table-column type="selection" label="全选" width="55"></el-table-column>
                        <el-table-column label="商品名称" prop="goodsName"></el-table-column>
                        <el-table-column label="商品编号" prop="goodsCode"></el-table-column>
                        <el-table-column label="经销价（元/件）" prop="supplyPrice"></el-table-column>
                        <el-table-column label="售价范围" prop="price"></el-table-column>
                        <el-table-column prop="sellPrice" label="销售价（元/件）">
                            <template slot-scope="scope">
                                <div class='tempList'>
                                    <el-input v-model.trim="scope.row.sellPrice" @blur="changeTable(scope.$index,scope.row)"
                                        placeholder="请输入销售价">
                                    </el-input>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="edit-tableBut-box">
                        <el-button class="edit-tableBut" @click="deleteRow()">删除行</el-button>
                    </div>
                    <div class="goodsSubmit">
                        <el-button type="primary" @click="submit()">提交</el-button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                title: '发布商品',
                itemName: '',
                tableData: [],
                multipleSection: [],
                dealerList: [],
                merchantList: [],
                notEdit: true,
                myForm: {
                    merchantId: '',
                },
                rules: {
                    merchantId: [{
                        required: true,
                        message: "请选择经销商",
                        trigger: ['change', 'blur'],
                    }],
                },
                restaurants: [],
                codeList: [],
            }
        },
        activated() {
            this.tableData = [];
            this.myForm.merchantId = '';
            this.itemName = '';
            this.notEdit = true;
            this.codeList = [];
            this.$nextTick(() => {
                if (this.$refs["myForm"]) {
                    this.$refs["myForm"].resetFields(); //重置表单
                }
            })
            this.getMerchantList(3)
        },
        deactivated() {
            this.notEdit = true;
            let pathList = this.$route.path.split('/')
            if (pathList[2] != 'storehouse') {
                this.cache.del('stockType');
            }
        },
        methods: {
            // 获取经销商列表
            getMerchantList(val) {
                if(val == 3){
                   this.post('/mall-service/merchant/v1/query/list',{roleType: 3}).then(res => {
                        res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                        res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
                        this.dealerList = res
                    }) 
                }
                if(val == 1){
                    this.post('/mall-service/merchant/v1/query/list',{roleType: 1}).then(res => {
                        res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
                        res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
                        this.merchantList = res
                    }) 
                }
            },
            hasValue(){
                if(this.myForm.merchantId) {
                    this.notEdit = false
                }
            },
            handSelectionChange(val) {
                // 选择的行
                this.multipleSection = val;
                console.log('multipleSection:', this.multipleSection)
            },
            changeTable(val, row) {
                this.tableData.forEach(item => {
                    if (item.index == row.index) {
                        item.sellPrice = row.sellPrice ? row.sellPrice : '';
                    }
                })
            },
            checkFun(row) {
                if (!row.sellPrice) {
                    this.$message({
                        message: '商品编号为' + row.goodsCode + '的销售价不能为空！',
                        type: 'warning',
                    })
                    return
                }
                if(row.lowPrice && row.hightPrice){
                    if (row.sellPrice < row.lowPrice || row.sellPrice > row.hightPrice) {
                        this.$message({
                            message: '商品编号为' + row.goodsCode + '的销售价不能超出售价范围！',
                            type: 'warning',
                        })
                        return
                    }
                }else {
                }
            },
            deleteRow() {
                if (this.multipleSection < 1) {
                    this.$message({
                        message: '至少选择一项！',
                        type: 'warning',
                    })
                    return
                }
                let selectIdList = [];
                this.multipleSection.forEach(item => {
                    selectIdList.push(item.index);
                })
                let saveList = [];
                this.codeList = [];
                this.tableData.forEach((item, index) => {
                    if (selectIdList.indexOf(item.index) == -1) {
                        saveList.push(item);
                        this.codeList.push(item.goodsCode);
                    }
                })
                this.tableData = saveList;
            },
            submit() {
                if (this.tableData.length < 1) {
                    this.$message({
                        message: '至少要录入一条商品数据',
                        type: 'warning'
                    })
                    return
                }
                let wrongfulFlag = false;
                this.tableData.find((item, index) => {
                    if(!item.sellPrice) {
                       this.checkFun(item);
                       return wrongfulFlag = true;
                    }
                    if(item.lowPrice && item.hightPrice) {
                        if(item.sellPrice < item.lowPrice || item.sellPrice > item.hightPrice){
                            this.checkFun(item);
                            return wrongfulFlag = true;
                        }
                    }
                })
                if (wrongfulFlag) {
                    return
                }
                this.$refs['myForm'].validate((valid) => {
                    if (valid) {
                        let dto = {}
                        let userInfo = this.cache.getLS("userInfo");
                        dto.merchantId = userInfo.merchantId
                        dto.merchantName = userInfo.merchantName
                        let itemsList = []
                        this.tableData.forEach(item => {
                            itemsList.push({id:item.id,sellPrice:item.sellPrice})
                        })
                        dto.itemsInfos = itemsList
                        this.post('mall-service/v1/items-info/add', dto, {
                            isUseResponse: true
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.$message({
                                    message: '发布商品成功！',
                                    type: 'success',
                                })
                                this.$back();
                            }
                        })
                        console.log('提交tableData： ', this.tableData)
                    }
                })

            },
            searchItem(queryString, cb) {
                var restaurants = [];
                if(!this.itemName) {
                    cb(restaurants);
                    return
                }
                restaurants = this.restaurants;
                let dto = {
                    merchantId: this.myForm.merchantId,
                    queryStr: this.itemName
                }
                this.post('mall-service/v1/items-info/query-items-info',dto).then(res => {
                    restaurants = res;
                    if (restaurants.length > 0) {
                        restaurants.forEach(item => {
                            if(item.merchantName) {
                                item.value = item.name + "/" + item.merchantName;
                            }else {
                               item.value = item.name;
                            }
                            
                        })
                    }
                    cb(restaurants);
                })

            },
            handleSelect(item) {
                if (this.codeList.indexOf(item.code) != -1) {
                    this.$message({
                        message: '商品编号重复！',
                        type: 'warning'
                    })
                    this.$refs.multipleTable.setCurrentRow(item);
                    return
                }
                console.log(item);
                item.lowPrice = item.lowPrice ? Number(item.lowPrice).toFixed(2) : '',
                item.hightPrice = item.hightPrice ? Number(item.hightPrice).toFixed(2) : '',
                this.tableData.unshift({
                    goodsName: item.name,
                    goodsCode: item.code,
                    supplyPrice: item.supplyPrice ? Number(item.supplyPrice).toFixed(2) : 0.00,
                    price: item.lowPrice + '-' + item.hightPrice,
                    id: item.id,
                    sellPrice: '',
                    index: this.tableData.length,
                    merchantName: item.merchantName ? item.merchantName : '--',
                })
                this.tableData.forEach(it => {
                    if (this.codeList.indexOf(it.goodsCode) == -1) {
                        this.codeList.push(it.goodsCode);
                    }
                })
                this.itemName = '';
            },
            must(obj) {
                // 库存数量加上 *号
                if (obj.columnIndex == 5) {
                    return 'must'
                }
            },
        },
    }
</script>
<style lang="scss" scoped>
    /deep/.el-table th.must>.cell:before {
        content: '*';
        color: red;
    }

    /deep/.el-table::before {
        z-index: 0;
    }

    .form-box {
        margin-left: 46px;
    }

    .baseInfo-title {
        margin-left: 46px;
        padding-bottom: 30px;
    }

    .goods-title {
        margin-left: 46px;
    }

    .goods-search {
        margin: 20px 92px;
        display: flex;

        div {
            line-height: 35px;
            padding-right: 5px;
        }
    }

    .table-box {
        margin-left: 92px;
        // display: flex;
    }

    .goods-table {
        height: 500px;
        // background: #eeeeee;
        margin-left: 68px;
    }

    .edit-tableBut-box {
        text-align: center
    }

    .edit-tableBut {
        margin: 20px 10px;
    }

    .goodsSubmit {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
</style>