var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: true } }),
      _c("div", { staticClass: "baseInfo" }, [
        _c("div", { staticClass: "baseInfo-title" }, [
          _vm._v("\n            选经销商\n        ")
        ]),
        _c(
          "div",
          { staticClass: "form-box" },
          [
            _c(
              "el-form",
              { ref: "myForm", attrs: { model: _vm.myForm, rules: _vm.rules } },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: { display: "inline-block" },
                    attrs: {
                      "label-width": "120px",
                      label: "经销商",
                      prop: "merchantId"
                    }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择供应商", filterable: "" },
                        on: {
                          change: function($event) {
                            _vm.hasValue()
                          }
                        },
                        model: {
                          value: _vm.myForm.merchantId,
                          callback: function($$v) {
                            _vm.$set(_vm.myForm, "merchantId", $$v)
                          },
                          expression: "myForm.merchantId"
                        }
                      },
                      _vm._l(_vm.dealerList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "goods" }, [
        _c("div", { staticClass: "goods-title" }, [_vm._v("选择经销商品")]),
        _c(
          "div",
          { staticClass: "goods-search" },
          [
            _vm._m(0),
            _c("el-autocomplete", {
              attrs: {
                "fetch-suggestions": _vm.searchItem,
                placeholder: "请输入商品名称/商品编号",
                clearable: "",
                disabled: _vm.notEdit
              },
              on: { select: _vm.handleSelect },
              model: {
                value: _vm.itemName,
                callback: function($$v) {
                  _vm.itemName = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "itemName"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "table-box" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "goods-table" },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: {
                    width: "100%",
                    height: "380px",
                    overflow: "auto"
                  },
                  attrs: {
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    border: "",
                    "header-cell-class-name": _vm.must
                  },
                  on: { "selection-change": _vm.handSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", label: "全选", width: "55" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品名称", prop: "goodsName" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品编号", prop: "goodsCode" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "经销价（元/件）", prop: "supplyPrice" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "售价范围", prop: "price" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sellPrice", label: "销售价（元/件）" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "tempList" },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入销售价" },
                                  on: {
                                    blur: function($event) {
                                      _vm.changeTable(scope.$index, scope.row)
                                    }
                                  },
                                  model: {
                                    value: scope.row.sellPrice,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "sellPrice",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "scope.row.sellPrice"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-tableBut-box" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "edit-tableBut",
                      on: {
                        click: function($event) {
                          _vm.deleteRow()
                        }
                      }
                    },
                    [_vm._v("删除行")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "goodsSubmit" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.submit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("商品查询"),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "padding-bottom": "8px", width: "100px" } },
      [
        _vm._v("商品明细"),
        _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }